<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_case_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange"  ref="tabs" ></mobileTab>
    <div class="mobile-case-box">
      <div class="case-title">传世打造中医院SPD经典案例</div>
      <img class="w100f" :src="spdImg1" alt="">
      <h2>客户简介</h2>
      <p>
        常熟市中医院始建于1958年，2003年和常熟市妇幼保健院合并，2011年通过评审成为三级乙等中医医院。是南京中医药大学附属医院，天津中医药大学、苏州大学教学医院，国家中医药管理局“治未病”预防保健服务试点单位、中医药康复服务能力建设单位，香港艾力彼中国县级医院竞争力排行榜百强医院。
      </p>
      <p>
        医院本部占地面积8.7万平方米，建筑面积10.3万平方米，核定床位850张；分院康复中心床位55张；另设独立虞山国医馆。下辖区域医共体1家，专科医共体19家。2019年门急诊量132.2万余人次，出院病人4.3万余人次，手术1.5万余例次。
      </p>
      <img class="w100f mt_026" :src="spdImg2" alt="">
      <img class="w100f mt_026" :src="spdImg3" alt="">
      <h2>项目介绍</h2>
      <p>
        为响应国家医改政策，规范医院医用耗材管理流程，提高医用耗材管理效率，降低医用耗材管理成本，探索医院医用耗材管理的新模式，医院选定了传世般若为医院提供院内耗材管理的平台运营服务。
      </p>
      <p>2019年10月，常熟市中医院耗材SPD信息系统正式上线。</p>
      <p>2019年11月，常熟市中医院耗材院内物流运营服务正式启用。</p>
      <h2>传世提供的服务</h2>
    </div>
    <img class="w100f" :src="spdImg4" alt="">
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      spdImg1:require('../../../assets/mobile_images/mo_case_spd1.png'),
      spdImg2:require('../../../assets/mobile_images/mo_case_spd2.png'),
      spdImg3:require('../../../assets/mobile_images/mo_case_spd3.png'),
      spdImg4:require('../../../assets/mobile_images/mo_case_spd4.png'),
      currentIndex: 0,
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.tabs.currentIndex = 1
    })
  },
  methods: {
    onTabChange(item, index) {
    //   this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-case-box{
    color: #3D3D3D;
    padding: 0 .4rem;
    box-sizing: border-box;
    .case-title{
      font-size: .4rem;
      text-align: left;
      padding: .1rem 0 .28rem 0;
    }
    h2{
      font-size: .32rem;
      text-align: left;
      margin: .46rem 0 .42rem 0;
    }
    p{
      font-size: .28rem;
      line-height: .5rem;
      text-align: left;
      text-indent: 2em;
      margin-bottom: .2rem;
    }
    .mt_026{
      margin-top: .26rem;
    }
  }
}
</style>
