<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/case_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">经典案例</p>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h95"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabs"> </Tab>
        <div class="case-title">传世打造中医院SPD经典案例</div>
        <img class="w100f" src="../../assets/images/case_spd1.png" alt="" />
        <div class="case-title">客户简介</div>
        <p>
          常熟市中医院始建于1958年，2003年和常熟市妇幼保健院合并，2011年通过评审成为三级乙等中医医院。是南京中医药大学附属医院，天津中医药大学、苏州大学教学医院，国家中医药管理局“治未病”预防保健服务试点单位、中医药康复服务能力建设单位，香港艾力彼中国县级医院竞争力排行榜百强医院。
        </p>
        <p>
          医院本部占地面积8.7万平方米，建筑面积10.3万平方米，核定床位850张；分院康复中心床位55张；另设独立虞山国医馆。下辖区域医共体1家，专科医共体19家。2019年门急诊量132.2万余人次，出院病人4.3万余人次，手术1.5万余例次。
        </p>
        <div class="h24"></div>
        <div class="case-img-box">
          <img class="w70f" src="../../assets/images/case_spd2.png" alt="" />
          <img class="w29f" src="../../assets/images/case_spd3.png" alt="" />
        </div>
        <div class="case-title">项目介绍</div>
        <p>
          为响应国家医改政策，规范医院医用耗材管理流程，提高医用耗材管理效率，降低医用耗材管理成本，探索医院医用耗材管理的新模式，医院选定了传世般若为医院提供院内耗材管理的平台运营服务。
        </p>
        <p>2019年10月，常熟市中医院耗材SPD信息系统正式上线。</p>
        <p>2019年11月，常熟市中医院耗材院内物流运营服务正式启用。</p>
        <div class="h95"></div>
        <div class="case-title mb20">传世提供的服务</div>
        <img class="serve-img mb20" src="../../assets/images/case_spd4.png" alt="" />
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
    
</template>

<script>
import mobilePage from '@/views/case/mobile/case_spd.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage
  },
  data() {
    return {
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  mounted(){
    this.$nextTick(()=>{
      this.$refs.tabs.currentIndex = 1
    })
  },
  methods: {
    onTabChange(index) {
      this.currentPageIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .case-title {
      font-size: 32px;
      text-align: left;
      margin: 43px 0;
    }
    p{
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .case-img-box{
      display: flex;
      justify-content: space-between;
    }
    .serve-img{
      width: 70%;
      margin-left: -30%;
    }
  }
}
</style>
